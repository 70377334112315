<template>
    <div>
		<div class="header">
			<img class="logo-empresa" :src="reporte.imagen" alt="logo"/>
			<span class="titulo-empresa">{{ reporte.empresa }}</span>
			<span class="direccion">{{ reporte.direccion }}</span>
			<span class="telefono">Telefono: {{ reporte.telefono }}</span>
			<span class="email">Email: {{ reporte.correo }}</span>
			<span class="ubicacion">Ciudad: {{ reporte.ubicacion }}</span>

			<div class="textoborde">
				<p class="no-margin fechareporte"><strong>F. Apertura: </strong> {{ reporte.fechaApertura }}</p>
				<p class="no-margin horareporte"><strong>F. Cierre: </strong> {{ reporte.fechaCierre}}</p>
				<p class="no-margin usuario"><strong>Usuario: {{ reporte.usuario.usuario }}</strong></p>
				<p class="no-margin almacen"><strong>{{ reporte.almacen.nombre }}</strong></p>
			</div>

			<span class="documento">{{ reporte.documento }}</span>
			<span class="estado">{{ reporte.operacionCaja }}</span>
		</div>

		<div class="footer">
			<center>
				<div class="css-no_print" align="center">
					<a-button onclick="print_default_printer()" type="primary"><a-icon type="printer" /> IMPRIMIR</a-button>
				</div>
			</center>
		</div>

		<table>
			<thead><tr><th><div class="t-head">&nbsp;</div></th></tr></thead>
			<tbody><tr><td><div class="content">
				<!--- CONTENIDO --->
				<span class="codigo">Codigo: {{ reporte.codigoArqueoCaja }}</span><br>

				<div class="source">
					<table>
						<thead>
							<tr>
								<th><strong>INGRESOS A CAJA</strong></th>
								<th><strong>IMPORTE</strong></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in reporte.lista_ingresos" :key="index">
								<td style="background-color: #ffffff; border-top: 0px solid #ffffff; padding: 5px; border-bottom: 1px solid rgb(180, 180, 180); text-align: left; font-size: 0.9em; width: 80%;">{{ item.nombre }}</td>
								<td style="background-color: #ffffff; border-top: 0px solid #ffffff; padding: 5px; border-bottom: 1px solid rgb(180, 180, 180);"><center>{{ item.subtotal }}</center></td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<th><font size=3>TOTAL INGRESOS A CAJA</font></th>
								<th><center><font size=4 style="color:green;">{{ reporte.subtotal_ingresos }}</font></center></th>
							</tr>
						</tfoot>
					</table>
					<br>
					<div class="right">
						<span class="subtotal_title"><strong>Nota de apertura:</strong> {{ reporte.nota }} :</span><br><br>
						<span class="subtotal"><strong>Observacion:</strong> {{ reporte.observacion }}</span><br><br>
						<span class="literal"><strong>Son:</strong> {{ reporte.montoliteral }}</span>
					</div>
				</div>

				<div class="destination">
					<table>
						<thead>
							<tr>
								<th><strong>GASTOS DE CAJA</strong></th>
								<th><strong>IMPORTE</strong></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in reporte.lista_egresos" :key="index">
								<td style="background-color: #ffffff; border-top: 0px solid #ffffff; padding: 5px; border-bottom: 1px solid rgb(180, 180, 180); text-align: left; font-size: 0.9em; width: 80%;">{{ item.nombre }}</td>
								<td style="background-color: #ffffff; border-top: 0px solid #ffffff; padding: 5px; border-bottom: 1px solid rgb(180, 180, 180);"><center>{{ item.subtotal }}</center></td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<th><font size=3>TOTAL GASTOS DE CAJA</font></th>
								<th><center><font size=4 style="color:#bd0000;">{{ reporte.subtotal_egresos }}</font></center></th>
							</tr>
						</tfoot>
					</table>
					<br>
					<table>
						<tfoot>
							<tr>
								<th><font size=2>SUBTOTAL</font></th>
								<th style="background-color:#fdfdfd;"><center><font size=4 style="color:#4d4d4d;">{{ reporte.total }}</font></center></th>
							</tr>
						</tfoot>
					</table>
					<br>
					<table>
						<tfoot>
							<tr>
								<th style="width:120px;"><font size=2>EFECTIVO ENTREGADO</font></th>
								<th style="background-color:#fdfdfd; width:80px;"><center><font size=4 style="color:#4d4d4d;">{{ reporte.efectivoEntregado }}</font></center></th>
							</tr>
							<tr>
								<th style="width:120px;">
									<font size=2>
										<span v-if="reporte.observacion_diferencia === 'DIFERENCIA'">{{ reporte.observacion_diferencia }}</span>
										<span v-if="reporte.observacion_diferencia === 'DIFERENCIA POSITIVO'" style="color:green;">{{ reporte.observacion_diferencia }}</span>
										<span v-if="reporte.observacion_diferencia === 'DIFERENCIA NEGATIVO'" style="color:#BC0000;">{{ reporte.observacion_diferencia }}</span>
									</font>
								</th>
								<th style="background-color:#fdfdfd; width:80px;"><center><font size=4 style="color:#4d4d4d;">{{ reporte.diferencia }}</font></center></th>
							</tr>
							<tr>
								<th style="width:120px;"><font size=1>SALDO SIGUIENTE TURNO</font></th>
								<th style="background-color:#fdfdfd; width:80px;"><center><font size=4 style="color:#008089;">{{ reporte.saldoCaja }}</font></center></th>
							</tr>
							<tr>
								<th><font size=3>TOTAL</font></th>
								<th><center><font size=4 style="color:green;">{{ reporte.total }}</font></center></th>
							</tr>
						</tfoot>
					</table>
				</div>

				<div class="source">
					<span style="font-size:20px;"><strong>Cajero</strong></span><br><br><br>
					<span style="font-size:15px;"><strong>Firma : </strong>....................................................................</span><br><br><br>
					<span style="font-size:15px;"><strong>Nombre : </strong>................................................................</span>
				</div>
				
				<div class="destination">
					<span style="font-size:20px;"><strong>Supervisor</strong></span><br><br><br>
					<span style="font-size:15px;"><strong>Firma : </strong>....................................................................</span><br><br><br>
					<span style="font-size:15px;"><strong>Nombre : </strong>................................................................</span>
				</div>

				<!--- CONTENIDO --->

				<center><span style="font-size:10px; color:black;"><br>Reporte generado por www.efectivo.io <br> Fecha Reporte {{ reporte.fechareporte }}</span></center>
			</div></td></tr></tbody>
			<tfoot><tr><td><div class="t-foot">&nbsp;</div></td></tr></tfoot>
		</table>

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.9); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import * as utils from '@/utils/utils'
  import router from '@/config/router'

  export default {

      props: {
          arqueocajaID: { type: String, required: true }
      },

      data () {
          return {
			 spinnerloading: false
          }
      }, 

      computed: {
          ...mapGetters('auth', ['user']),
          ...mapGetters('arqueocaja', ['reporte'])
      },

      methods: {
          ...mapActions('arqueocaja', ['getReporte']),

		  imprimir () {
			 this.spinnerloading = true
			 this.getReporte(this.arqueocajaID)
			 .then(response => {
                this.spinnerloading = false
             }).catch(error => {
                utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
             })
		  }
      },

	  mounted () {
		  this.imprimir()
	  }
  }
</script>

<style scoped>
	@page {
		margin: 0mm;
		size: 218mm; 
		size: portrait;
	}

	@media print {
		body {border: 0;}
		thead {display: table-header-group;} 
		tfoot {display: table-footer-group;}
		button {display: none;}
		body {margin: 0;}
	}

	body {
		margin: 0 auto;
		font-family: 'Space Mono', monospace;
		max-width: 800px;
		background-color: #525252;
		width: 100%;
		border: 1px solid #ffff;
	}

	.col-md-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}

	.source, .destination {
		float: left;
		width: 48%;
		margin: 0;
		padding: 0;
	}
	.source {
		margin-right: 4%;
	}

		table {
			width: 100%;
		}
		table thead td {
			background-color: #54585d;
			color: #ffffff;
			font-weight: bold;
			font-size: 13px;
			border: 1px solid #54585d;
		}
		th, td {	
			width: 25%;
			text-align: left;
			vertical-align: top;
			padding: 0.3em;
			caption-side: bottom;
		}
		caption {
			padding: 0.3em;
			color: #fff;
			background: #000;
		}
		th {
			background: #eee;
		}

		.tabla-listado {
			background-color: #ffffff; 
			border-top: 0px solid #ffffff; 
			padding: 5px; 
			border-bottom: 1px solid rgb(180, 180, 180);
			font-size: 10px;
		}

		.header, .t-head, .footer, .t-foot {
			height: 100px; max-width: 803px; width: 100%;
			background: #fff;
		}

		.footer, .t-foot { height: 50px; }
		.t-head, .t-foot { background: #fff; }

		.pr {
		position: fixed; right: 0; bottom: 10px;
		color: #aaa; letter-spacing: 1px;
		font-weight: normal; text-decoration: none;
		}

		.header{
			background: #fcfcfc;
			position: fixed; 
			top: 0;
			border-bottom: 1px solid #ffff;
		}

		.footer {
			background: #fcfcfc;
			position: fixed; 
			bottom: 0;
			border-top: 1px solid #ffff;
		}

		.content {
			background: #fefefe;
			padding: 10px;
		}

		.content .page {
			page-break-after: always;
		}

		.logo-empresa{
			position:absolute;
			width:80px;
			height:auto;
			left : 10px;
			z-index: 1;
			top:10px;
		}

		.titulo-empresa{
			position:absolute;
			font-size: 11px;
			color:black;
			font-weight:700;
			letter-spacing: 0.50;
			left : 97px;
			z-index: 1;
			top: 12px;
		}

		.direccion{
			position:absolute;
			font-size: 9px;
			font-weight:400;
			color:black;
			left : 97px;
			z-index: 1;
			top: 28px;
		}

		.telefono{
			position:absolute;
			font-size: 8px;
			font-weight:400;
			color:black;
			left : 97px;
			z-index: 1;
			top: 42px;
		}

		.celular{
			position:absolute;
			font-size: 8px;
			font-weight:400;
			color:black;
			left : 97px;
			z-index: 1;
			top: 56px;
		}

		.email{
			position:absolute;
			font-size: 8px;
			font-weight:400;
			color:black;
			left : 97px;
			z-index: 1;
			top: 55px;
		}

		.ubicacion{
			position:absolute;
			font-size: 9px;
			font-weight:400;
			color:black;
			left : 97px;
			z-index: 1;
			top: 68px;
		}

		.textoborde {
			position:absolute;
			width: 185px;
			height: 70px;
			background-color:#F5F5F5;
			border:1.2px solid #DDDDDD;  
			text-align: right;
			line-height : 1px;
			overflow:hidden;
			padding:5px;
			-webkit-border-radius: 4px;
			border-radius: 4px;
			left : 570px;
			z-index: 1;
			top:20px;
			padding-top: 5px;
			padding-right: 5px;
			padding-bottom: 0px;
			padding-left: 5px;
		}

		.fechareporte{
			position:absolute;
			font-size: 10px;
			font-weight:400;
			color:black;
			left : 6px;
			z-index: 1;
			top: 10px;
		}

		.horareporte{
			position:absolute;
			font-size: 10px;
			font-weight:400;
			color:black;
			left : 6px;
			z-index: 1;
			top: 23px;
		}

		.usuario{
			position:absolute;
			font-size: 12px;
			font-weight:400;
			color:black;
			left : 6px;
			z-index: 1;
			top: 38px;
		}

		.almacen{
			position:absolute;
			font-size: 11px;
			font-weight:400;
			color:black;
			left : 6px;
			z-index: 1;
			top: 53px;
		}

		.css-data_table{
			width: 100%;
			border: none;
			border-collapse: collapse;
			border-spacing: 2px 0px;
		}
				
		.css-data_table th {
			border-bottom: 1px solid rgb(14, 14, 14);      
			font-weight: 700;
			text-align: center;
			font-size: 0.9em;
			font-weight:700;
		}

		.css-data_table td{
			border: 1px solid black;
			padding: 5px;
			text-align: center;
			font-size: 0.6em;
			font-weight:400;
		}

		.documento{
			position:absolute;
			font-size: 18px;
			font-weight:700;
			color:black;
			left : 300px;
			z-index: 1;
			top: 40px;
		}

		.codigo{
			font-size: 16px;
			font-weight:700;
			color:rgb(65, 65, 65);
		}

		.estado{
			position:absolute;
			font-size: 15px;
			font-weight:700;
			color:rgb(179, 0, 0);
			left : 340px;
			z-index: 1;
			top: 70px;
		}

		.fecha{
			position:absolute;
			font-size: 13px;
			font-weight:40;
			color:black;
			left : 320px;
			z-index: 1;
			top: 60px;
		}
</style>